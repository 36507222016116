.section {
    &__title {
        .section.is-partners & {
            margin: {
                bottom: 64px;
            }
        }

        &__heading {
            .section.is-partners & {
                max-width: 780px;
            }
        }
    }

    &__logos {
        .section.is-partners & {
            display: flex;
            margin: {
                left: auto;
                right: auto;
            }
            justify-content: center;
            align-items: center;
        }
    }

    &__logo {
        .section.is-partners & {
            max-width: 100%;
            flex: {
                shrink: 0;
            }
        }

        & + & {
            .section.is-partners & {
                margin: {
                    left: 80px;
                }
            }
        }
    }

    &.is-partners {
        margin: {
            bottom: 64px;
        }
    }

    @include breakpoint-below("md") {
        &__title {
            .section.is-partners & {
                margin: {
                    bottom: 32px;
                }
            }
        }

        &__logos {
            .section.is-partners & {
                display: flex;
                flex: {
                    direction: column;
                }
            }
        }

        &__logo {
            & + & {
                .section.is-partners & {
                    margin: {
                        top: 32px;
                        left: 0;
                    }
                }
            }
        }

        &.is-partners {
            margin: {
                bottom: 64px;
            }
        }
    }
}
