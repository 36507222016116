.button {
    display: flex;
    height: 48px;
    padding: 16px;
    font: {
        family: font-family("primary");
        weight: font-weight("semi-bold");
        size: font-size(16px);
    }
    text: {
        decoration: none;
    }
    line-height: font-size(16px);
    border: {
        radius: 40px;
    }
    flex: {
        shrink: 0;
    }
    transition: background-color .15s ease-in-out,
                color .15s ease-in-out;
    align-items: center;

    &.is-primary {
        color: color("white");
        background: {
            color: color("yellow");
        }

        &:hover {
            background-color: darken(color("yellow"), 15%);
        }
    }

    &.is-secondary {
        border: {
            color: color("dark-blue");
            style: solid;
            width: 2px;
        }

        &:hover {
            color: color("white");
            background: {
                color: color("dark-blue");
            }
        }
    }
}
