/*------------------------------------*\
  #COLORS
\*------------------------------------*/

$colors: (
    "dark-blue": #193136,
    "dark-gray": #171717,
    "yellow": #ecb540,
    "purple": #a987bb,
    "white": #fff,
    "black": #000,
    "blue": #154273,
    "gray": #595959,
);





/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/

$font-families: (
    "primary": ( "Montserrat", sans-serif ),
);

$font-weights: (
    "thin": 100,
    "extra-light": 200,
    "light": 300,
    "regular": 400,
    "medium": 500,
    "semi-bold": 600,
    "bold": 700,
    "extra-bold": 800,
    "black": 900,
);

$font-size: 14px;





/*------------------------------------*\
  #GRID
\*------------------------------------*/

$breakpoints: (
    "xs": 0,
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1025px,
);

$container-mobile-gutter-width: 32px;
$container-width: 960px;





/*------------------------------------*\
  #Z-INDEX MASTER LIST
\*------------------------------------*/

$z-index: (
    "mobile-nav": 1000,
);
