.section {
    &__icon {
        display: block;
        width: 108px;
        height: 108px;
        margin: {
            right: auto;
            bottom: 64px;
            left: auto;
        }
        background: {
            repeat: no-repeat;
            size: 108px 108px;
        }
    }

    &__title {
        position: relative;
        display: flex;
        margin: {
            bottom: 32px;
        }
        align-items: center;
        flex: {
            direction: column;
        }

        &__no {
            position: absolute;
            top: 50%;
            left: 50%;
            font: {
                family: font-family("primary");
                weight: font-weight("semi-bold");
                size: font-size(150px);
            }
            color: rgba(#ededed, .75);
            line-height: font-size(150px);
            transform: translate(-50%, -50%);
            pointer-events: none;
            user-select: none;
        }

        &__heading {
            position: relative;
            z-index: 1;
            max-width: 620px;
            text: {
                align: center;
            }
        }

        &__subtitle {
            position: relative;
            z-index: 1;
            display: block;
            font: {
                family: font-family("primary");
                weight: font-weight("semi-bold");
                size: font-size(12px);
            }
            color: #8d8d8d;
            text: {
                transform: uppercase;
            }
            line-height: font-size(16px);
            letter-spacing: 2px;
        }
    }
}
