.section {
    &__icon {
        .section.is-problem & {
            margin: {
                bottom: 32px;
            }
            background: {
                image: url("../images/section_problem.svg");
            }
        }
    }

    &__title {
        .section.is-problem & {
            margin: {
                bottom: 32px;
            }
        }

        &__no {
            .section.is-problem & {
                color: rgba(#cbe0f1, .5);
            }
        }
    }

    &__columns {
        .section.is-problem & {
            display: flex;
            justify-content: space-between;

            .paragraph {
                font: {
                    size: font-size(16px);
                }
                line-height: font-size(24px);

                & + .paragraph {
                    margin: {
                        top: 16px;
                    }
                }
            }
        }
    }

    &__column {
        .section.is-problem & {
            width: calc(50% - 32px);
        }
    }

    &.is-problem {
        padding: {
            top: 64px;
            bottom: 64px;
        }
        background: {
            color: #eff7fc;
        }
        box-shadow: inset 0 1px 0 rgba(color("black"), .1);

        .container {
            max-width: 750px;
        }

        .paragraph.is-lead {
            margin: {
                bottom: 32px;
            }
            color: rgba(color("dark-gray"), .6);
            text: {
                align: center;
            }
        }
    }

    @include breakpoint-below("xl") {
        &.is-problem {
            .container {
                max-width: unset;
            }
        }
    }

    @include breakpoint-below("md") {
        &__columns {
            .section.is-problem & {
                display: block;
            }
        }

        &__column {
            .section.is-problem & {
                width: 100%;
            }

            & + & {
                .section.is-problem & {
                    margin: {
                        top: 16px;
                    }
                }
            }
        }

        &.is-problem {
            padding: {
                top: 64px;
                bottom: 64px;
            }

            .paragraph.is-lead {
                margin: {
                    bottom: 16px;
                }
                padding: {
                    right: 16px;
                    left: 16px;
                }
                font: {
                    weight: font-weight("regular");
                    size: font-size(20px);
                }
                line-height: font-size(32px);
            }

            .paragraph {
                text: {
                    align: center;
                }
            }
        }
    }
}
