.header {
    display: flex;
    width: 100%;
    height: 80px;
    padding: {
        right: 32px;
        left: 32px;
    }
    background: {
        color: color("white");
    }
    border: {
        bottom: {
            color: rgba(color("black"), .1);
            style: solid;
            width: 1px;
        }
    }
    align-items: center;

    &__brand {
        display: block;
        font: {
            family: font-family("primary");
            weight: font-weight("semi-bold");
            size: font-size(18px);
        }
        color: color("blue");
        line-height: font-size(22px);
        flex: {
            shrink: 0;
        }
    }

    &__right {
        display: flex;
        margin: {
            left: auto;
        }
        align-items: center;
    }

    &__hamburger-menu {
        display: none;
        padding: {
            top: 8px;
            right: 8px;
            bottom: 8px;
            left: (8px + (4px * 2));
        }
        font: {
            family: font-family("primary");
            weight: font-weight("semi-bold");
            size: font-size(18px);
        }
        color: color("dark-blue");
        line-height: font-size(22px);
        background: {
            position: left 4px center;
            repeat: no-repeat;
            image: url("../images/icons/hamburger_dark-blue.svg");
            color: transparent;
            size: 8px 10px;
        }
        border: {
            width: 0;
        }
        cursor: pointer;

        &:active,
        &:focus {
            outline: none;
        }
    }

    &__nav {
        display: flex;

        &__link {
            display: block;
            font: {
                family: font-family("primary");
                weight: font-weight("semi-bold");
                size: font-size(16px);
            }
            color: color("dark-blue");
            text: {
                decoration: none;
            }
            line-height: font-size(19px);
            transition: color .15s ease-in-out;

            &:hover {
                color: darken(color("dark-blue"), 15%);
            }

            & + & {
                margin: {
                    left: 32px;
                }
            }
        }
    }

    &__register {
        margin: {
            left: 32px;
        }
    }

    @media (max-width: 1410px) {
        padding: {
            right: 16px;
            left: 16px;
        }

        &__nav {
            display: none;
        }

        &__hamburger-menu {
            display: block;
        }
    }

    @include breakpoint-below("md") {
        &__brand {
            display: none;
        }
    }
}
