.container {
    max-width: $container-width;
    margin: {
        right: auto;
        left: auto;
    }

    @media (max-width: ($container-width + ($container-mobile-gutter-width * 2))) {
        margin: {
            right: $container-mobile-gutter-width;
            left: $container-mobile-gutter-width;
        }
    }
}
