.mobile-nav {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: z-index("mobile-nav");
    display: none;
    height: 100vh;
    padding: 32px;
    background: {
        color: color("white");
    }
    box-shadow: 0 0 15px rgba(color("black"), .1);
    transition: right .15s ease-in-out;
    align-items: flex-start;
    flex: {
        direction: column;
    }

    &__close {
        position: absolute;
        top: 32px;
        right: 32px;
        display: block;
        width: 24px;
        height: 24px;
        text: {
            indent: -9999px;
        }
        background: {
            position: center center;
            repeat: no-repeat;
            image: url("../images/icons/close_dark-blue.svg");
            color: transparent;
            size: 16px 16px;
        }
        border: {
            width: 0;
        }
        cursor: pointer;
    }

    &__link {
        font: {
            family: font-family("primary");
            weight: font-weight("semi-bold");
            size: font-size(18px);
        }
        color: color("dark-blue");
        text: {
            decoration: none;
        }
        line-height: font-size(21px);

        & + & {
            margin: {
                top: 24px;
            }
        }
    }

    @media (max-width: 1410px) {
        display: flex;
        width: 50%;
    }

    @include breakpoint-below("md") {
        width: calc(100% - 32px);
    }
}
