.hero {
    position: relative;
    display: flex;
    width: 100%;
    background: {
        repeat: no-repeat;
        image: url("../images/hero_background.png");
        size: cover;
    }
    align-items: flex-start;
    flex: {
        direction: column;
    }

    &__content {
        position: relative;
        width: 100%;
        max-width: 740px;
        margin: {
            top: 140px;
            right: 256px;
            left: auto;
        }
        padding: 50px;
        background: {
            color: color("white");
        }
        border: {
            color: color("dark-blue");
            style: solid;
            width: 4px;
        }
        box-shadow: 0 8px 256px rgba(color("black"), .1);

        > .heading {
            margin: {
                bottom: 16px;
            }
        }

        > .paragraph {
            margin: {
                bottom: 32px;
            }
            font: {
                size: 21px;
            }
            color: color("dark-blue");
            line-height: font-size(30px);
        }

        &__actions {
            display: flex;

            > .button + .button {
                margin: {
                    left: 16px;
                }
            }
        }

        &::before {
            position: absolute;
            top: -(140px + 4px);
            left: -4px;
            width: 80px;
            height: 140px;
            background: {
                repeat: no-repeat;
                image: url("../images/rijkslogo_lint.svg");
                size: 80px 140px;
            }
            content: "";
        }
    }

    &__dates {
        display: block;
        margin: {
            top: 25px;
        }
        padding: {
            top: 25px;
            right: 64px;
            bottom: 25px;
            left: 64px;
        }
        font: {
            family: font-family("primary");
            weight: font-weight("regular");
            size: font-size(16px);
        }
        color: color("white");
        text: {
            align: center;
        }
        line-height: font-size(30px);
        background: {
            color: color("blue");
        }
        box-shadow: 0 8px 256px rgba(color("black"), .1);
    }

    @media (max-width: (740px + (256px * 2))) {
        height: auto;

        &__content {
            margin: {
                right: auto;
            }
        }

        &__dates {
            width: 100%;
            padding: {
                right: 16px;
                left: 16px;
            }
        }
    }

    @media (max-width: (740px + (16px * 2))) {
        &__content {
            max-width: calc(100% - (16px * 2));
            margin: {
                right: 16px;
                left: 16px;
            }
            padding: 16px;

            > .paragraph {
                margin: {
                    bottom: 16px;
                }
                font: {
                    size: font-size(18px);
                }
                line-height: font-size(28px);
            }
        }
    }
}
