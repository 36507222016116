@function font-family($name) {
    @if map-has-key($font-families, $name) {
        @return map-get($font-families, $name);
    }

    @error "#{$name} is not a `font-family`.";
}

@function font-weight($name) {
    @if map-has-key($font-weights, $name) {
        @return map-get($font-weights, $name);
    }

    @error "#{$name} is not a `font-weight`.";
}

@function font-size($size) {
    @return ($size / $font-size) * 1rem;
}
