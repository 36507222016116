.section {
    &__title {
        .section.is-register & {
            margin: {
                bottom: 64px;
            }
        }

        &__no {
            .section.is-register & {
                color: rgba(color("dark-blue"), .1);
            }
        }

        &__heading {
            .section.is-register & {
                max-width: 700px;
            }
        }
    }

    &__actions {
        .section.is-register & {
            display: flex;
            justify-content: center;

            > .button + .button {
                margin: {
                    left: 16px;
                }
            }
        }
    }

    &.is-register {
        padding: {
            top: 96px;
            bottom: 64px;
        }
        background: {
            color: #f6f6f6;
        }
    }

    @include breakpoint-below("xl") {
        &.is-register {
            padding: {
                bottom: 64px;
            }
        }
    }

    @include breakpoint-below("md") {
        &__title {
            .section.is-register & {
                margin: {
                    bottom: 32px;
                }
            }
        }

        &.is-register {
            padding: {
                top: 64px;
                bottom: 64px;
            }
        }
    }
}
