.section {
    &__icon {
        .section.is-prizes & {
            background: {
                image: url("../images/section_prizes.svg");
            }
        }
    }

    &__title {
        .section.is-prizes & {
            margin: {
                bottom: 64px;
            }
        }

        &__no {
            .section.is-prizes & {
                color: rgba(#cbe0f1, .5);
            }
        }
    }

    &__prizes {
        .section.is-prizes & {
            display: flex;
            margin: {
                bottom: 32px;
            }
            padding: {
                right: 64px;
                left: 64px;
            }
            justify-content: space-around;
        }
    }

    &__prize {
        .section.is-prizes & {
            display: flex;
            height: 137px;
            justify-content: center;
            align-items: center;
            flex: {
                direction: column;
            }

            &.is-winner {
                background: {
                    position: center center;
                    repeat: no-repeat;
                    image: url("../images/prizes_wreath.svg");
                    size: 161px 137px;
                }
            }
        }

        &__amount {
            .section.is-prizes & {
                display: block;
                margin: {
                    bottom: 12px;
                }
                font: {
                    family: font-family("primary");
                    weight: font-weight("semi-bold");
                    size: font-size(36px);
                }
                color: color("dark-gray");
                text: {
                    align: center;
                }
                line-height: font-size(28px);
            }
        }

        &__label {
            .section.is-prizes & {
                display: block;
                font: {
                    family: font-family("primary");
                    weight: font-weight("semi-bold");
                    size: font-size(24px);
                }
                color: darken(#ddebf6, 25%);
                text: {
                    transform: uppercase;
                    align: center;
                }
                letter-spacing: 2px;
                line-height: font-size(28px);
            }
        }
    }

    &__additional-prize {
        .section.is-prizes & {
            display: block;
            max-width: 680px;
            margin: {
                right: auto;
                left: auto;
            }
            font: {
                family: font-family("primary");
                weight: font-weight("regular");
                size: font-size(16px);
            }
            color: color("dark-gray");
            text: {
                align: center;
            }
            line-height: font-size(24px);
        }
    }

    &.is-prizes {
        margin: {
            bottom: 64px;
        }
        padding: {
            top: 64px;
            bottom: 64px;
        }
        background: {
            color: #eff7fc;
        }
    }

    @include breakpoint-below("md") {
        &__title {
            .section.is-prizes & {
                margin: {
                    bottom: 96px;
                }
                padding: 0;
            }
        }

        &__prizes {
            .section.is-prizes & {
                display: block;
                margin: {
                    bottom: 0;
                }
            }
        }

        &__prize {
            & + & {
                .section.is-prizes & {
                    margin: {
                        top: 32px;
                    }
                }
            }
        }
    }
}
