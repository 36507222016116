.section {
    &__icon {
        .section.is-who & {
            background: {
                image: url("../images/section_who.svg");
            }
        }
    }

    &__title {
        .section.is-who & {
            margin: {
                bottom: 64px;
            }
        }
    }

    &__categories {
        .section.is-who & {
            display: flex;
            justify-content: center;
            flex: {
                wrap: wrap;
            }
        }
    }

    &__category {
        .section.is-who & {
            position: relative;
            max-width: calc(100% / 3);
            margin: -.5px;
            padding: 24px;
            background: {
                color: color("white");
            }
            border: {
                color: #e9e9e9;
                style: solid;
                width: 1px;
            }
            cursor: pointer;
        }

        &__icon {
            .section.is-who & {
                position: relative;
                z-index: 2;
                display: block;
                width: 108px;
                height: 108px;
                margin: {
                    right: auto;
                    bottom: 16px;
                    left: auto;
                }
                background: {
                    repeat: no-repeat;
                    size: 108px 108px;
                }
            }
        }

        &__title {
            .section.is-who & {
                position: relative;
                z-index: 2;
                display: block;
                margin: {
                    bottom: 16px;
                }
                font: {
                    family: font-family("primary");
                    weight: font-weight("semi-bold");
                    size: font-size(26px);
                }
                color: color("dark-gray");
                text: {
                    align: center;
                }
                line-height: font-size(32px);
                letter-spacing: .2px;
            }
        }

        .paragraph {
            .section.is-who & {
                position: relative;
                z-index: 2;
                font: {
                    weight: font-weight("regular");
                    size: font-size(16px);
                }
                text: {
                    align: center;
                }
                line-height: font-size(24px);
                letter-spacing: .2px;
            }
        }
    }

    &.is-who {
        margin: {
            bottom: 64px;
        }
    }

    @include breakpoint-above("md") {
        &__category {
            .section.is-who & {
                @include hover {
                    &::before {
                        position: absolute;
                        top: -5px;
                        left: -5px;
                        z-index: 1;
                        width: calc(100% + (5px * 2));
                        height: calc(100% + (5px * 2));
                        background: {
                            color: color("white");
                        }
                        box-shadow: 0 8px 20px rgba(color("black"), .2);
                        opacity: 0;
                        transition: opacity .15s ease-in-out;
                        content: "";
                    }

                    &:hover::before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include breakpoint-below("md") {
        &__icon {
            .section.is-who & {
                display: none;
            }
        }

        &__categories {
            .section.is-who & {
                display: block;
            }
        }

        &__category {
            .section.is-who & {
                width: 100%;
                max-width: unset;
                margin: 0;
                padding: 0;
                border: {
                    width: 0;
                }
            }

            & + & {
                .section.is-who & {
                    margin: {
                        top: 32px;
                    }
                }
            }
        }
    }
}
