html,
body {
    font: {
        family: font-family("primary");
        weight: font-weight("regular");
        size: $font-size;
    }
    color: color("dark-gray");
    line-height: 24px;
}





/*------------------------------------*\
  #HEADINGS
\*------------------------------------*/

.heading {
    margin: {
        bottom: 0;
    }
    font: {
        family: font-family("primary");
    }
    color: color("dark-gray");

    &.is-1 {
        font: {
            weight: font-weight("semi-bold");
            size: font-size(44px);
        }
        line-height: font-size(52px);
        letter-spacing: 1.4px;
    }

    &.is-2 {
        font: {
            weight: font-weight("semi-bold");
            size: font-size(40px);
        }
        line-height: font-size(50px);
    }

    @include breakpoint-below("md") {
        &.is-1 {
            font: {
                size: font-size(32px);
            }
            line-height: font-size(36px);
            letter-spacing: 1.6px;
        }

        &.is-2 {
            font: {
                size: font-size(32px);
            }
            line-height: font-size(36px);
        }
    }
}





/*------------------------------------*\
  #PARAGRAPHS
\*------------------------------------*/

.paragraph {
    margin: {
        bottom: 0;
    }
    font: {
        family: font-family("primary");
        weight: font-weight("light");
        size: font-size(18px);
    }
    color: color("gray");
    line-height: font-size(30px);

    &.is-lead {
        font: {
            weight: font-weight("semi-bold");
            size: font-size(24px);
        }
        color: color("dark-gray");
        line-height: font-size(32px);
    }

    @include breakpoint-below("md") {
        font: {
            size: font-size(16px);
        }
        line-height: font-size(24px);
    }
}
