.footer {
    display: block;
    padding: {
        top: 32px;
        right: 16px;
        bottom: 32px;
        left: 16px;
    }
    font: {
        family: font-family("primary");
        weight: font-weight("regular");
        size: font-size(16px);
    }
    color: rgba(color("dark-gray"), .6);
    text: {
        align: center;
    }

    > a {
        color: inherit;
    }
}
