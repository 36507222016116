.section {
    &__icon {
        .section.is-agenda & {
            background: {
                image: url("../images/section_agenda.svg");
            }
        }
    }

    &__day {
        .section.is-agenda & {
            width: 100%;
        }

        &__date {
            .section.is-agenda & {
                display: block;
                margin: {
                    bottom: 32px;
                }
                padding: {
                    left: 64px;
                }
                font: {
                    family: font-family("primary");
                    weight: font-weight("semi-bold");
                    size: font-size(26px);
                }
                color: color("purple");
                line-height: font-size(26px);
                letter-spacing: 1px;
            }
        }

        &__header {
            .section.is-agenda & {
                display: flex;
                padding: {
                    top: 8px;
                    bottom: 8px;
                }
                font: {
                    family: font-family("primary");
                    weight: font-weight("semi-bold");
                    size: font-size(12px);
                }
                color: #8d8d8d;
                text: {
                    transform: uppercase;
                }
                line-height: font-size(16px);
                letter-spacing: 2px;
                background: {
                    color: #f6f6f6;
                }
            }
        }

        &__row {
            .section.is-agenda & {
                display: flex;
                padding: {
                    top: 16px;
                    bottom: 16px;
                }
                font: {
                    family: font-family("primary");
                    weight: font-weight("light");
                    size: font-size(18px);
                }
                color: color("dark-gray");
                line-height: font-size(24px);
                letter-spacing: .18px;
                align-items: flex-start;
            }

            & + & {
                .section.is-agenda & {
                    border: {
                        top: {
                            color: #ececec;
                            style: solid;
                            width: 1px;
                        }
                    }
                }
            }
        }

        &__time {
            .section.is-agenda & {
                display: flex;
                width: 20%;
                padding: {
                    left: 64px;
                }
                flex: {
                    shrink: 0;
                }
            }

            &__start {
                .section.is-agenda & {
                    display: block;
                    margin: {
                        right: 16px;
                    }
                    padding: {
                        right: (16px + 20px);
                    }
                    background: {
                        position: right center;
                        repeat: no-repeat;
                        image: url("../images/icons/clock_gray.svg");
                        size: 20px 20px;
                    }
                }
            }

            &__end {
                .section.is-agenda & {
                    display: block;
                }
            }
        }

        &__time-event {
            .section.is-agenda & {
                display: none;
                padding: {
                    right: 16px;
                    left: 16px;
                }
            }
        }

        & + & {
            .section.is-agenda & {
                margin: {
                    top: 32px;
                }
            }
        }
    }

    &__experts {
        display: block;
        max-width: 816px;
        margin: {
            top: 32px;
            right: auto;
            left: auto;
        }
        padding: {
            top: 28px;
            right: 40px;
            bottom: 28px;
            left: 40px;
        }
        font: {
            family: font-family("primary");
            weight: font-weight("regular");
            style: italic;
            size: font-size(18px);
        }
        color: #82b6e0;
        text: {
            align: center;
        }
        line-height: font-size(26px);
        background: {
            color: #eff7fc;
        }
        border: {
            radius: 8px;
        }
    }

    &.is-agenda {
        margin: {
            bottom: 64px;
        }
        padding: {
            bottom: 64px;
        }
        border: {
            bottom: {
                color: #e9e9e9;
                style: solid;
                width: 1px;
            }
        }
    }

    @include breakpoint-below("xl") {
        &__day {
            &__date {
                .section.is-agenda & {
                    padding: {
                        left: 32px;
                    }
                }
            }

            &__time {
                .section.is-agenda & {
                    padding: {
                        left: 32px;
                    }
                }
            }
        }

        &.is-agenda {
            margin: {
                bottom: 96px;
            }
            padding: {
                bottom: 96px;
            }
        }
    }

    @include breakpoint-below("md") {
        &__title {
            .section.is-agenda & {
                margin: {
                    bottom: 64px;
                }
            }
        }

        &__day {
            &__date {
                .section.is-agenda & {
                    margin: {
                        bottom: 16px;
                    }
                    padding: {
                        right: 16px;
                        left: 16px;
                    }
                    font: {
                        size: font-size(24px);
                    }
                    line-height: font-size(29px);
                    letter-spacing: .86px;
                }
            }

            &__header {
                .section.is-agenda & {
                    .section__day__event,
                    .section__day__time {
                        display: none;
                    }
                }
            }

            &__row {
                .section.is-agenda & {
                    padding: 16px;
                    flex: {
                        direction: column-reverse;
                    }
                }
            }

            &__time {
                .section.is-agenda & {
                    padding: {
                        left: 0;
                    }
                    font: {
                        size: font-size(16px);
                    }
                    color: rgba(color("dark-gray"), .6);
                    line-height: font-size(19px);
                }

                &__start {
                    .section.is-agenda & {
                        margin: {
                            right: 10px;
                        }
                        padding: {
                            right: (10px + 14px);
                        }
                        background: {
                            size: 14px 14px;
                        }
                    }
                }
            }

            &__event {
                .section.is-agenda & {
                    margin: {
                        bottom: 8px;
                    }
                    font: {
                        size: font-size(18px);
                    }
                }
            }

            &__time-event {
                .section.is-agenda & {
                    display: block;
                }
            }
        }

        &__experts {
            margin: {
                top: 16px;
                right: 16px;
                left: 16px;
            }
            padding: 16px;
            font: {
                size: font-size(16px);
            }
            line-height: font-size(28px);
        }

        &.is-agenda {
            margin: {
                bottom: 64px;
            }
            padding: {
                bottom: 64px;
            }
        }
    }
}
