.section {
    &__title {
        &__heading {
            .section.is-hackathon & {
                max-width: unset;
            }
        }
    }

    &__photos {
        .section.is-hackathon & {
            display: flex;
            max-width: 704px;
            justify-content: space-between;
            flex: {
                wrap: wrap;
            }
        }
    }

    &__photo {
        .section.is-hackathon & {
            width: 347px;
            height: 347px;
            background: {
                position: center center;
                color: #eee;
                size: cover;
            }
            flex: {
                shrink: 0;
            }

            &.is-large {
                width: 704px;
                margin: {
                    bottom: 10px;
                }
            }
        }
    }

    &__content {
        .section.is-hackathon & {
            margin: {
                left: auto;
                right: auto;
            }
            padding: {
                left: 100px;
                right: 100px;
            }
            flex: {
                shrink: 1;
            }

            > .paragraph {
                text: {
                    align: center;
                }
            }
        }
    }

    &.is-hackathon {
        display: flex;
        width: 100%;
        max-width: 1440px;
        margin: {
            right: auto;
            bottom: 64px;
            left: auto;
        }
        padding: {
            top: 10px;
            bottom: 10px;
        }
        align-items: center;
    }

    @media (max-width: (1440px + (10px * 2))) {
        &__photos {
            .section.is-hackathon & {
                margin: {
                    left: 10px;
                }
            }
        }

        &.is-hackathon {
            max-width: unset;
        }
    }

    @media (max-width: 1113px) {
        &__title {
            .section.is-hackathon & {
                margin: {
                    bottom: 64px;
                }
            }
        }

        &__photos {
            .section.is-hackathon & {
                margin: {
                    right: 0;
                    bottom: 64px;
                }
            }
        }

        &__content {
            .section.is-hackathon & {
                max-width: 768px;
                padding: {
                    right: 0;
                    left: 0;
                }
            }
        }

        &.is-hackathon {
            display: flex;
            padding: {
                bottom: 32px;
            }
            flex: {
                direction: column;
            }
        }
    }

    @include breakpoint-below("md") {
        &__title {
            .section.is-hackathon & {
                margin: {
                    bottom: 64px;
                }
            }

            &__no {
                .section.is-hackathon & {
                    color: #ededed;
                }
            }
        }

        &__photos {
            .section.is-hackathon & {
                width: calc(100% + (16px * 2));
                max-width: unset;
                margin: {
                    right: 0;
                    bottom: 64px;
                    left: -16px;
                }
            }
        }

        &__photo {
            .section.is-hackathon & {
                display: none;

                &.is-large {
                    display: block;
                    width: 100%;
                }
            }
        }

        &.is-hackathon {
            display: block;
            margin: {
                top: 10px;
                bottom: 64px;
            }
            padding: {
                top: 0;
                right: 16px;
                bottom: 32px;
                left: 16px;
            }
            background: {
                color: #f4f5f6;
            }
        }
    }
}
